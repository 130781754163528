import * as React from "react"
import styled from "styled-components"

const MainLogo = styled.nav`
background-color: hsl(0deg, 0%,0%);
color:hsl(0deg, 100%,100%);
text-align: center;
& svg{
  max-width:150px;
}
& .a-better-view{
  display: none;
}

& .nexoptic path,polygon{
  fill:hsl(0deg, 100%,100%);
}
`
    
const Logo = () => {
    return (
        <MainLogo>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="250px" height="40px" viewBox="0 0 250 40">
        <g class="nexoptic">
        <g>
            <polygon points="105.5886002,8.1135778 99.8935852,8.1135778 99.8935852,24.2625923 87.6035843,8.1135778 82.2825775,8.1135778 
                82.2825775,34.3415985 87.9785843,34.3415985 87.9785843,17.6685848 100.6805878,34.3415985 105.5886002,34.3415985 		"></polygon>
            <path d="M119.4929962,13.8831863c-5.8450012,0-9.9290009,4.7220039-9.9290009,10.4540062v0.0750008
                c0,6.1450062,4.4590073,10.3790092,10.4910049,10.3790092c3.6350098,0,6.3330078-1.4239998,8.2060165-3.7470036
                l-3.2590103-2.8850021c-1.5739975,1.4610023-2.9610062,2.0610027-4.8710098,2.0610027
                c-2.548996,0-4.3470001-1.3490028-4.9089966-3.9340038h13.9760132c0.0370026-0.5249977,0.0749969-1.0499992,0.0749969-1.4990005
                C129.2730103,19.0911903,126.201004,13.8831863,119.4929962,13.8831863 M115.1470032,22.7261925
                c0.4489975-2.585001,1.9859924-4.2720032,4.345993-4.2720032c2.3990021,0,3.8970108,1.7240047,4.2350082,4.2720032H115.1470032z"></path>
            <polygon points="149.8076324,14.2580833 143.8496094,14.2580833 140.0656128,20.1040897 136.3186035,14.2580833 
                130.2116089,14.2580833 136.9936066,24.1500931 129.9486084,34.3410988 135.9066162,34.3410988 139.9526062,28.0850945 
                144.0006104,34.3410988 150.1076202,34.3410988 143.0256042,24.0370941 		"></polygon>
            <path d="M165.4480286,7.6636796c-8.0940094,0-13.9760132,6.108007-13.9760132,13.5640106v0.0750008
                c0,7.4560051,5.8070068,13.4880123,13.901001,13.4880123c8.0930176,0,13.9760132-6.1070061,13.9760132-13.5630131v-0.0749969
                C179.3490295,13.6966858,173.5410309,7.6636796,165.4480286,7.6636796 M165.4480286,29.4706974
                c-4.6460114,0-7.9440002-3.7470016-7.9440002-8.2430077v-0.0749969c0-4.496006,3.2229919-8.1680098,7.868988-8.1680098
                c4.6460266,0,7.9430237,3.7460051,7.9430237,8.2430067v0.0750008
                C173.31604,25.7986965,170.0940399,29.4706974,165.4480286,29.4706974"></path>
            <path d="M194.3534546,13.8837852c-2.9609985,0-4.871994,1.3860025-6.2579956,3.2590008v-2.8850002h-5.6950226v26.0790215
                h5.6950226v-8.6180096c1.348999,1.611002,3.2220001,2.9970036,6.2579956,2.9970036
                c4.7579956,0,9.1410217-3.6710033,9.1410217-10.3790092v-0.0739975
                C203.4944763,17.555788,199.0364532,13.8837852,194.3534546,13.8837852 M192.8914642,29.8827972
                c-2.6600037,0-4.8710022-2.210001-4.8710022-5.5460052v-0.0739975c0-3.3350048,2.2109985-5.5460052,4.8710022-5.5460052
                c2.6609955,0,4.9079895,2.2110004,4.9079895,5.5460052v0.0739975
                C197.7994537,27.7097969,195.5524597,29.8827972,192.8914642,29.8827972"></path>
            <path d="M213.5776825,9.1248779h-5.6960144v5.133007h-2.397995v4.8710022h2.397995v9.5180054
                c0,4.6460037,2.3610077,6.0320053,5.8460083,6.0320053c1.9109955,0,3.2969971-0.4500008,4.4960022-1.1619987v-4.5710068
                c-0.8249969,0.4500008-1.7610016,0.7120037-2.8099976,0.7120037c-1.2740021,0-1.8359985-0.6370029-1.8359985-1.9480038v-8.5810051
                h4.720993V14.257885h-4.720993V9.1248779z"></path>
            <path d="M227.8553925,6.989378h-5.9949951v5.0580025h5.9949951V6.989378z M227.7053986,14.2583847h-5.6959991v20.0830154
                h5.6959991V14.2583847z"></path>
            <path d="M241.8700104,13.8834839c-6.1450195,0-10.5290222,4.7210045-10.5290222,10.45401v0.0750008
                c0,5.7330017,4.4210052,10.3790054,10.4530182,10.3790054c4.0099945,0,6.2579956-1.5359993,8.2059937-3.6720028
                l-3.3339996-3.3720016c-1.3869934,1.3110008-2.697998,2.1350021-4.6089935,2.1350021
                c-3.1100159,0-5.0960236-2.472002-5.0960236-5.5450039v-0.0750008c0-2.960001,2.0230103-5.4710045,4.8710175-5.4710045
                c2.0240021,0,3.2980042,0.8619995,4.5709991,2.2110043l3.4850006-3.7470055
                C248.0149994,15.1574869,245.654007,13.8834839,241.8700104,13.8834839"></path>
        </g>
        </g>
        <g class="diamond">
        <path fill="#FF8F1C" d="M69.1690598,23.6629829L45.5060196,0L34.5845604,10.9210291L23.663044,0L0,23.6629829l23.663044,23.6629829
            l10.9215164-10.9210281l10.9214592,10.9210281L69.1690598,23.6629829z M23.663044,43.6860085L3.6409941,23.6629829
            L23.663044,3.640933l9.1007156,9.1007767L21.8419971,23.6629829l10.9220676,10.9215775L23.663044,43.6860085z
            M34.5845604,14.5624504l9.1004715,9.1005325l-9.1004715,9.1009598l-9.1005306-9.1009598L34.5845604,14.5624504z
            M47.3270645,23.6629829L36.405304,12.7417097l9.1007156-9.1007767l20.022049,20.022049l-20.022049,20.0230255
            l-9.1010208-9.1014481L47.3270645,23.6629829z"></path>
        </g>
        </svg>
        </MainLogo>)
    }
    export { Logo }