import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

const Wrapper = styled.div``;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <Wrapper>
    {parse(`<iframe
      src="https://www.youtube.com/watch?v=SkBbJncL4IQ&t=1s"
      title="Nexoptic"
      loading="lazy"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />`)}
  </Wrapper>
);
export default Video;
