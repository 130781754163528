import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: hsl(0deg, 0% 0%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  gap: 20px;
`;
const TickerItem = styled.span`
  font-weight: bold;
  display: inline-block;
  color: inherit;
`;
const Ticker = () => {
  return (
    <Wrapper>
      <TickerItem>OTCQB: NXOPF </TickerItem>
      <TickerItem>TSX-V: NXO</TickerItem>
    </Wrapper>
  );
};

export { Ticker };
