import * as React from "react";
import parse from "html-react-parser";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Globals } from "../components/globalstyles";
import { Logo } from "../components/mainlogo";
import { Bps } from "../components/breakpoints";
import { Ticker } from "../components/ticker";
import { Hero } from "../components/hero";
import { SideBy } from "../components/sideby";
import { FeaturesSection } from "../components/features";
import { Slider } from "../components/slider";
import { FeaturedIn } from "../components/featuredin";
import { Cta } from "../components/cta";
import Video from "../components/video";
import { InfoCards } from "../components/infocards";
import { BottomCta } from "../components/bottomcta";
import SEO from "../components/seo";

// import { Hero } from '../components/hero';
// import { Imagecta } from "../components/imagecta";
// import { graphql } from 'gatsby'
// import { ImageLoader } from "../components/imageLoader";
// import { Clients } from "../components/clients";

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
`;
const Header = styled.header`
  ${Bps.m} {
    background-color: black;
    padding: 0 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
  }
`;

const ContentWrapper = styled.main``;

// markup
const IndexPage = ({ data }) => {
  const content = data.wpLandinghere.landingPageFields;
  return (
    <Wrapper>
      <SEO />
      <Globals />
      <Header>
        <Logo />
        <Ticker />
      </Header>
      <ContentWrapper>
        <Hero
          byline={content.byline}
          headingpre={content.heroHeadingPre}
          headingemph={content.heroHeadingEmph}
          headingpost={content.heroHeadingPost}
          herocontent={parse(`${content.heroParagraph}`)}
          Link={content.button}
        />
        <SideBy
          divimage={content.textOnLeft.image.localFile}
          alttag={content.textOnLeft.image.altText}
          paragraph={content.textOnLeft.text}
        />
        <SideBy
          reverse
          badge
          divimage={content.textOnRightWithBadge.image.localFile}
          alttag={content.textOnRightWithBadge.image.altText}
          paragraph={content.textOnRightWithBadge.text}
          badgeLabel={content.textOnRightWithBadge.badgeLabel}
          firstImage={content.textOnRightWithBadge.svg1}
          secondImage={content.textOnRightWithBadge.svg2}
          thirdImage={content.textOnRightWithBadge.svg3}
        />
        <FeaturesSection
          heading={content.featuresSection.heading}
          paragraph={parse(`${content.featuresSection.paragraph}`)}
          firstlistheading={content.featuresSection.firstListHeading}
          secondlistheading={content.featuresSection.secondListHeading}
          list={parse(`${content.featuresSection.firstList}`)}
          list2={parse(`${content.featuresSection.secondList}`)}
        />
        <Slider gql={content.gallery} />
        <FeaturedIn heading="Featured in:" links={content.featuredLinks} />
        <Cta
          headingpre={content.cta.headingPre}
          headingemph={content.cta.headingEmph}
          headingpost={content.cta.headingPost}
          ButtonText={content.cta.link.title}
          Link={content.cta.link.url}
          secondaryheading={content.cta.secondaryHeading}
          ctacontent={content.cta.paragraph}
          left="true"
        />
        <InfoCards
          heading={content.impactHeading}
          cards={content.impactPosts}
        />
        <BottomCta heading={content.bottomCta} />
      </ContentWrapper>
    </Wrapper>
  );
};

export default IndexPage;

export const query = graphql`
  {
    wpLandinghere(id: { eq: "cG9zdDo3OA==" }) {
      id
      landingPageFields {
        byline
        heroHeadingEmph
        heroHeadingPost
        heroHeadingPre
        heroParagraph
        herobutton
        bottomCta
        impactHeading
        impactPosts {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            altText
          }
          paragraph
          title
        }
        cta {
          headingEmph
          headingPost
          headingPre
          paragraph
          secondaryHeading
          link {
            target
            title
            url
          }
        }
        featuredLinks {
          link {
            target
            title
            url
          }
        }
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        featuresSection {
          firstListHeading
          secondListHeading
          firstList
          heading
          paragraph
          secondList
        }
        textOnLeft {
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          text
        }
        textOnRightWithBadge {
          badgeLabel
          svg1
          svg2
          svg3
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
