import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { Video } from "./video";
import parse from "html-react-parser";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(345deg, 6%, 13%, 0.75);
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.show ? "visible" : "none")};
`;
const ModalContent = styled.div`
  width: calc(100% - 36px);
  height: auto;
  max-height: 550px;
  max-width: 900px;
  border: 8px solid hsla(281deg, 100%, 100%, 0.4);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  position: relative;

  /* & article {
    width: calc(100% - 36px);
    max-height: 350px;
    max-width: 550px;
    background-color: #fff;
    border: 8px solid hsla(281deg, 100%, 100%, 0.4);
    opacity: 100%;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  } */

  /* & div {
    height: 100%;
    padding-bottom: 25%;
    position: relative;
  } */
  /* & iframe {
    position: absolute;
    top: 0;
  } */
`;
const ModalClose = styled.div`
  position: absolute;
  top: -24px;
  left: 95%;
`;
const Close = styled.svg`
  width: 48px;
  height: 48px;
`;
const Modal = (props) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <ModalWrapper {...props} onClick={props.onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div>
          <LiteYouTubeEmbed
            id="SkBbJncL4IQ"
            title="NEXOPTIC"
            poster="hqdefault"
            webp="true"
          />
          <ModalClose>
            <Close
              onClick={props.onClose}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              fill="black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </Close>
          </ModalClose>
        </div>
      </ModalContent>
    </ModalWrapper>
  );
};

export { Modal };
