import * as React from "react"
import styled from "styled-components"
import { Bps } from "../components/breakpoints";


const Btn = styled.a`
width: calc( 100% - 40px );
display: block;
margin:24px auto;
text-transform: capitalize;
background-color: hsl(0deg 0% 0% );
color:hsl(0deg, 100% , 100%);
border:none;
padding:8px 0;
font-weight: bold;
text-align: center;
text-decoration: none;

${Bps.m}{
    max-width:fit-content;
    padding:8px 24px;
}

`

const PrimaryButton = (props) => {
    return (
        <Btn href={props.Link}>{props.ButtonText}</Btn>
    )
}

export { PrimaryButton }