import React from "react";
import styled from "styled-components";
import { ImageLoader } from "./imageloader";
import { Bps } from "./breakpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/keyboard";
import "swiper/css/lazy";
import "swiper/css/mousewheel";
import "./slider.css";
const Wrapper = styled.section`
  background-color: var(--primary-color);
  padding: 60px 0;
  padding-left: var(--edge-padding);
  ${Bps.m} {
    padding: 60px var(--edge-padding);
  }
  ${Bps.lg} {
    padding: 84px var(--edge-padding);
  }
`;
const Heading = styled.h2`
  font-size: var(--h1-size);
  color: var(--secondary-color);
  font-weight: var(--h1-weight);
  line-height: var(--h1-line);
  text-align: center;
  padding-left: 10px;
  padding-right: 28px;
  padding-bottom: 48px;
`;

const CardHeading = styled.h3`
  color: var(--secondary-color);
  padding-top: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
`;

const CardBody = styled.p`
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 300;
`;
const styles = {
  slider: {
    objectFit: "cover",
    height: "auto",
  },
  sliderContainer: {
    height: "auto",
  },
};

const InfoCards = (props) => {
  const cardArray = props.cards;
  return (
    <Wrapper>
      <Heading>{props.heading}</Heading>
      <Swiper
        className="infoCard"
        spaceBetween={25}
        slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 1.3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {cardArray.map((edge) => (
          <SwiperSlide style={styles.slider}>
            <div>
              <ImageLoader divimage={edge.image.localFile} />
              <CardHeading>{edge.title}</CardHeading>
              <CardBody>{parse(`${edge.paragraph}`)}</CardBody>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export { InfoCards };
