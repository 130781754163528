import * as React from "react";
import styled from "styled-components";
import { ModalVideo } from "./modalvideo";
import { Bps } from "./breakpoints";

const Wrapper = styled.section`
  background-color: var(--secondary-color);
  padding: 48px var(--edge-padding);
  max-width: 900px;
  margin: 0 auto;
`;

const Heading = styled.h3`
  -webkit-text-stroke: 2px var(--accent-color1);
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 900;
  line-height: 37px;
  ${Bps.m} {
    font-size: 40px;
    font-weight: 900;
    line-height: 49px;
  }
`;
const BottomCta = (props) => {
  return (
    <Wrapper>
      <Heading>{props.heading}</Heading>
      <div class="_form_1"></div>
    </Wrapper>
  );
};

export { BottomCta };
