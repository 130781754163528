import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Modal } from "./modal";
import { Bps } from "./breakpoints";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Bps.m} {
    justify-content: ${(props) => (props.left ? "center" : "center")};
    align-items: ${(props) => (props.left ? "flex-start" : "center")};
  }
`;
const VideoButton = styled.button`
  width: 100%;
  background-color: ${(props) =>
    props.alt ? "var(--primary-color)" : "var(--accent-color1)"};
  border: none;
  color: var(--secondary-color);
  padding: 12px 0;
  cursor: pointer;
  ${Bps.m} {
    max-width: fit-content;
    padding: 12px 40px;
  }
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.alt ? "var(--accent-color1)" : "var(--primary-color)"};
    transition: all 0.2s ease-in-out;
  }
`;

const ModalVideo = (props) => {
  const [show, setShow] = useState(false);
  return (
    <Wrapper {...props}>
      <VideoButton {...props} onClick={() => setShow(true)}>
        {" "}
        Watch Chairman Interview
      </VideoButton>
      <Modal show={show} onClose={() => setShow(false)} />
    </Wrapper>
  );
};

export { ModalVideo };
