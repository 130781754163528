import * as React from "react";
import styled from "styled-components";
import { Bps } from "../components/breakpoints";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  ${Bps.lg} {
    /* flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
    min-height: 500px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". .";
    width: 100%;
    height: 100%;
  }
`;

const TextBlock = styled.div`
  padding: 0 var(--edge-padding);
  padding-top: 42px;
  padding-bottom: ${(props) => (props.badge ? "16px" : "42px")};
  text-align: center;
  background-color: ${(props) =>
    props.reverse ? "var(--primary-color)" : "white"};
  & p {
    margin: 20px auto;
  }
  ${Bps.m} {
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  ${Bps.lg} {
    grid-column: ${(props) => (props.reverse ? "2" : "1")};
    grid-row: 1;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 80px;
  }

  & > div {
    color: ${(props) => (props.reverse ? "#fff" : "#000")};
    max-width: calc(var(--content-width) + 80px);
    margin: 0 auto;
  }
`;

const ImageBlock = styled.div`
  background-color: black;
  width: 100%;
  max-height: 229px;
  overflow: hidden;

  display: ${(props) => (props.reverse ? "block" : "none")};
  ${Bps.sm} {
    max-height: 250px;
  }
  ${Bps.m} {
    height: 100%;
    max-height: 100%;
    & .gatsby-image-wrapper {
      width: 100%;
    }
  }
  ${Bps.lg} {
    grid-column: ${(props) => (props.reverse ? "1" : "2")};
    grid-row: 1;
    width: 100%;
    display: flex;
    max-height: 454px;
    height: 100%;
    object-fit: cover;
    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  & img {
    width: 100%;
    height: 100%;
    ${Bps.m} {
      object-fit: cover;
      object-position: top;
    }
    ${Bps.lg} {
      max-height: unset;
    }
  }
`;

const BadgeWrapper = styled.div`
  display: ${(props) => (props.badge ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
`;
const LogoBar = styled.div`
  max-width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  gap: 36px;
  padding: 12px 48px;
  align-items: center;
  justify-content: center;
  border-radius: 999px;

  & img {
    max-height: 32px;
    max-width: 86px;
  }
  & img:nth-child(1) {
    max-height: 32px;
    max-width: 86px;
  }
  & img:nth-child(2) {
    max-height: 32px;
    max-width: 86px;
  }
  & img:nth-child(3) {
    max-height: 16px;
    max-width: 86px;
  }
`;
const Badge = styled.div`
  background-color: var(--accent-color2);
  border-radius: 999px;
  max-width: fit-content;
  padding: 4px 12px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -8px;
  ${Bps.lg} {
  }
`;
const SideBy = (props) => {
  const image = getImage(props.divimage);
  return (
    <Wrapper {...props}>
      <TextBlock {...props}>
        <div> {parse(`${props.paragraph}`)}</div>
        <BadgeWrapper {...props}>
          <LogoBar>
            <img src={props.firstImage} />
            <img src={props.secondImage} />
            <img src={props.thirdImage} />
          </LogoBar>
          <Badge>{props.badgeLabel}</Badge>
        </BadgeWrapper>
      </TextBlock>
      <ImageBlock {...props}>
        <GatsbyImage image={image} alt={props.alttag} />
      </ImageBlock>
    </Wrapper>
  );
};

export { SideBy };
