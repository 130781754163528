import * as React from "react";
import styled from "styled-components";
import { Bps } from "./breakpoints";
import parse from "html-react-parser";

const Wrapper = styled.section`
  margin: 0 auto;
  padding-top: 44px;
  ${Bps.m} {
    padding-top: 75px;
  }
  max-width: 1280px;
`;
const Heading = styled.h2`
  font-weight: bold;
  text-align: center;
  padding: 0 calc(var(--edge-padding) + 10px);
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: var(--h1-line);
  max-width: 900px;
  margin: 0 auto;
`;
const Paragraph = styled.p`
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 var(--edge-padding);
  font-size: 20px;
  line-height: 30px;
  ${Bps.m} {
    margin-top: 36px;
  }
  max-width: 900px;
`;
const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  ${Bps.lg} {
    padding: 0 130px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
  }
`;
const ListWrapper = styled.div`
  width: 100%;
  padding: 24px;
  padding-top: 48px;
  background-color: var(--primary-color);
  color: white;

  ${Bps.m} {
    width: 481px;
    border-radius: 10px;
    margin: 0 auto;
    padding: 0 10px;
    padding-top: 48px;
    margin-bottom: 45px;
  }
  ${Bps.lg} {
    /* margin: 0 auto;  */
  }

  & p {
    text-align: center;
    margin: 10px 0 20px 0;
    font-size: 16px;
    padding: 0 20px;
    line-height: 1.5;
  }
`;
const ListHeading = styled.h3`
  font-weight: 400;
  text-align: center;
  line-height: 2;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;

  ${Bps.m} {
    margin: 0 auto;
    width: fit-content;
    padding: 0 50px;
  }
`;
const List = styled.div`
  text-align: left;

  padding-top: 20px;
  ${Bps.m} {
    padding: 0 45px;
  }
  & ul {
    position: relative;
    list-style: none;
  }
  & ul li {
    position: relative;
    padding: 0 0 0 20px;
  }
  & ul li:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: -5px;
    top: 10px;
    position: absolute;
    background-color: white;
  }
`;
const Divider = styled.hr`

  border: 0;
  height: 0.35rem;
  display: block;
  background-image: -webkit-linear-gradient(
    0deg,
    transparent,
    #f29c38,
    #ea3329,
    #ff034a,
    #e532ad,
    #0c8cfe,
    transparent
  );
  clear: both;
  margin:0;
  margin-top:48px;
}
`;

const FeaturesSection = (props) => {
  const firstlist = props.firstlistheading;
  const secondlist = props.secondlistheading;
  return (
    <Wrapper>
      <Heading>{props.heading}</Heading>
      <Paragraph>{props.paragraph}</Paragraph>
      <ListSection>
        <ListWrapper>
          <ListHeading>{props.firstlistheading}</ListHeading>
          <p>
            There is virtually no imaging system that can’t be made better with
            ALIIS. ALIIS is AI for the Sight Revolution
          </p>
          <List>{props.list}</List>
          <Divider />
        </ListWrapper>
        <ListWrapper>
          <ListHeading>{props.secondlistheading}</ListHeading>
          <List>{props.list2}</List>
          <Divider />
        </ListWrapper>
      </ListSection>
    </Wrapper>
  );
};

export { FeaturesSection };
