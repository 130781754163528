import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const ImageLoader = (props) => {
  const image = getImage(props.divimage)
    return (
      <GatsbyImage image={image} alt={props.alttag} />
      
    )
  }
  
  export { ImageLoader }
  