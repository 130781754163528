import * as React from "react";
import styled from "styled-components";
import { ImageLoader } from "./imageloader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/keyboard";
import "swiper/css/lazy";
import "swiper/css/mousewheel";
import "./slider.css";

const Wrapper = styled.section`
  background-color: var(--primary-color);
  padding: 25px 0;
  padding-left: var(--edge-padding);
`;

const styles = {
  slider: {
    objectFit: "cover",
    height: "232px",
  },
  sliderContainer: {
    height: "232px",
  },
};

const Slider = (props) => {
  const imgArray = props.gql;
  return (
    <Wrapper>
      <Swiper
        className="image-slider"
        spaceBetween={25}
        slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 50,
          },
          1280: {
            slidesPerView: 3.4,
            spaceBetween: 50,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {imgArray.map((edge) => (
          <SwiperSlide style={styles.slider}>
            {" "}
            <ImageLoader divimage={edge.localFile} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export { Slider };
