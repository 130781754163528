import * as React from "react";
import styled from "styled-components";
import { Bps } from "./breakpoints";

const Wrapper = styled.section`
  padding-top: 55px;
  padding-bottom: 55px;
  background-image: linear-gradient(0deg, #fe8e15 0%, #6e318a 73%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  ${Bps.m} {
    min-height: 350px;
  }
  & > div {
    padding: 0 var(--edge-padding);

    display: flex;
    flex-direction: column;
    gap: 15px;

    ${Bps.m} {
      margin: 0 auto;
      display: grid;
      max-width: fit-content;
      grid-template-columns: 240px;
      grid-template-rows: repeat(3, 50px);
      grid-column-gap: 0px;
      grid-row-gap: 30px;
    }
    ${Bps.lg} {
      grid-template-rows: repeat(2, 50px);
    }
  }
`;
const Heading = styled.h2`
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: var(--h1-line);
  color: var(--secondary-color);
  ${Bps.m} {
    grid-area: 1 / 1 / 2 / 2;
  }
`;
// const LinkWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
// `;
const Link = styled.a`
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  color: white;
  text-decoration: none;
  position: relative;
  width: fit-content;
  &:after {
    content: "";
    background-color: var(--secondary-color);
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: -6px;
    left: 0;
    transition: all 0.1s ease-in-out;
  }
  &:hover:after {
    content: "";
    background-color: var(--secondary-color);
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: all 0.1s ease-in-out;
  }
  
  ${Bps.m} {
    font-size: 36px;
    font-weight: var(--h1-weight);
    line-height: 44px;
    color: var(--secondary-color);

    &:nth-of-type(1) {
      grid-area: 1 / 2 / 2 / 3;
    }
    &:nth-of-type(2n) {
      grid-area: 2 / 1 / 3 / 3;
    }
    &:last-of-type {
      grid-area: 3 / 1 / 4 / 3;
    }

    ${Bps.lg} {
    font-size: 36px;
    font-weight: var(--h1-weight);
    line-height: 44px;
    color: var(--secondary-color);

    &:nth-of-type(1) {
      grid-area: 2 / 1 / 3 / 3;
    }
    &:nth-of-type(2n) {
      grid-area: 1 / 2 / 1 / 4;
    }
    &:last-of-type {
      margin-left:120px;
      grid-area: 2 / 2 / 2 / 4;
    }
  }
  &:after {
    content: "";
    background-color: var(--secondary-color);
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: -6px;
    left: 0;
    transition: all 0.1s ease-in-out;
  }
  &:hover:after {
    content: "";
    background-color: var(--secondary-color);
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: all 0.1s ease-in-out;
  }
`;

const FeaturedIn = (props) => {
  const repeater = props.links;
  return (
    <Wrapper>
      <div>
        <Heading>{props.heading}</Heading>

        {repeater.map((linkrow) => (
          <Link href={linkrow.link.url} target={linkrow.link.target}>
            {linkrow.link.title}
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};

export { FeaturedIn };
