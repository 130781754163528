import * as React from "react";
import styled from "styled-components";
import { Bps } from "./breakpoints";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-fam);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

h1 {
  font-size:var(--h1-size);
  line-height:var(--h1-line);
  font-weight:var(--h1-weight);
}
h2 {
  font-size:var(--h2-size);
  line-height:var(--h2-line);
  font-weight:var(--h2-weight);
}
h3 {
  font-size:var(--h3-size);
  line-height:var(--h3-line);
  font-weight:var(--h3-weight);
}
p {
  font-size:var(--p-size);
  line-height:var(--p-line);
  font-weight:var(--p-weight);
}
  :root {
    --font-fam:'Proxima Nova' , sans-serif;
    --edge-padding:18px;
    --primary-color:hsl(345deg, 6%, 13%);
    --secondary-color:hsl(345deg, 100%, 100%);
    --accent-color1:hsl(281deg, 48%, 37%);
    --accent-color2:hsl(172deg, 47%, 59%);
    --h1-size:26px;
    --h1-line:32px;
    --h1-weight:800;
    ${Bps.m}{
      --content-width:586px;
      --h1-size:40px;
      --h1-line:49px;
      --h1-weight:800;
      --edge-padding:40px;
    }
    ${Bps.lg}{
      --h1-size:40px;
      --h1-line:49px;
      --h1-weight:800;
    }
  
    ${Bps.xl}{
      --h1-size:40px;
      --h1-line:40px;
      --h1-weight:800;
    }
    --h2-size:16px;
    --h2-line:20px;
    --h2-weight:700;
    ${Bps.m}{
      --h2-size:16px;
    --h2-line:20px;
    --h2-weight:700;
    }
    ${Bps.lg}{
      --h2-size:16px;
    --h2-line:20px;
    --h2-weight:700;
    }
  
    ${Bps.xl}{
      --h2-size:16px;
    --h2-line:20px;
    --h2-weight:700;
    }
    --h3-size:50px;
    --h3-line:61px;
    --h3-weight:800;
    ${Bps.m}{
      --h3-size:50px;
      --h3-line:61px;
      --h3-weight:800;
    }
    ${Bps.lg}{
      --h3-size:50px;
      --h3-line:61px;
      --h3-weight:800;
    }
  
    ${Bps.xl}{
      --h3-size:50px;
      --h3-line:61px;
      --h3-weight:800;
    }
    --p-size:20px;
    --p-line:26px;
    --p-weight:300;
    ${Bps.m}{
      --p-size:24px;
    --p-line:30px;
    --p-weight:300;
    }
    ${Bps.lg}{
      --p-size:24px;
      --p-line:30px;
      --p-weight:300;
    }
  
   
  }
  
`;
const Globals = () => {
  return <GlobalStyles />;
};

export { Globals };
